@import './app-variables';

.mat-button-wrapper {
  text-transform: uppercase;
  // float: left;
}

.mat-dialog-content {
  div,
  span,
  p {
    // color: $foundational-color-7;
    font-size: 16px;
  }

  .mat-input-element,
  .mat-select-value-text * {
    color: $foundational-color-10;
    font-size: $base-font-size-web;
  }
}

mat-icon:hover {
  cursor: pointer;
}

// This is for Photo Gallery Dialog Box, Do not modify
.photoGalleryPanel .mat-dialog-container {
  background-color: black;
  padding: 0px !important;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  min-width: 100vw;
  min-height: 100vw;
  max-width: 100vw;
}

.fullscreen-dialog .mat-dialog-container {
  // max-width: none;
  width: 100vw;
  height: 100vh;
  min-width: 100vw;
  min-height: 100vw;
  max-width: 100vw;
  background-color: rgba(0, 0, 0, 0.87) !important;
  padding: 0px !important;
  overflow: hidden;
  border-radius: 0px;
}
